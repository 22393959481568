import React, { useEffect } from "react";

const Modal = ({ t, resetState }) => {
  useEffect(() => {
    sessionStorage.setItem('projectId', -1)
  }, []);
  return (
    <section className="modal--background">
      <main className="modal--card">
        <div className="modal-text">
          <p>
            {t('Modal message.message1')} <br/>
            {t('Modal message.message2')}
          </p>
          <p>
            {t('Modal message.message3')}<br/>
            {t('Modal message.message4')}
          </p>
          <p>{t('Modal message.message5')}</p>
        </div>
          <button className="modal-button--style" onClick={() => { resetState() }}>{t('Configure another project')} </button>
      </main>
    </section>
  )
}
 export default Modal;