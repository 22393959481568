import React, { useMemo, useCallback } from "react";
import "./item_list.css"

const ItemList = ({
  items,
  onChange,
  idPrefix,
  defaultValue,
  margin,
  disabled,
}) => {
  const handleRadioChange = useCallback(
    (event) => {
      onChange(items[event.target.value].value);
    },
    [items, onChange]
  );

  const Items = useMemo(() => {
    return items.map((item, index) => (
      <label
        key={index}
        htmlFor={`${idPrefix}-${index}`}
        style={{ marginBottom: "0px", marginTop: margin ? "16px" : "0px" }}
      >
        <div
        className="item-list--container"
        >
          <div
            style={{
              WebkitFilter: disabled ? "grayscale(100%)" : null,
              filter: disabled ? "grayscale(100%)" : null,
            }}
          >
            {item.label}
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <input
              id={`${idPrefix}-${index}`}
              type="radio"
              checked={items[index].value === defaultValue}
              value={index}
              onChange={handleRadioChange}
              style={{
                cursor: "pointer",
                marginLeft: "90%",
              }}
              disabled={disabled}
            />
          </div>
        </div>
      </label>
    ));
  }, [defaultValue, disabled, handleRadioChange, idPrefix, items, margin]);

  return <>{Items}</>;
};

export default ItemList;
