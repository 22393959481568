const ralColors = {
  colors: [
    { code: "SA0007", name: "SA0007 (Verzinkt, ohne Beschichtung)", hex: "8A9597" },
    { code: "RAL9010", name: "RAL9010 (Pure white)", hex: "FFFFFF" },
    { code: "RAL7035", name: "RAL7035 (Light grey)", hex: "D7D7D7" },
    { code: "RAL7016", name: "RAL7016 (Anthracite grey)", hex: "293133" },
    { code: "RAL7006", name: "RAL7006 (Beige grey)", hex: "6D6552" },
    { code: "RAL6005", name: "RAL6005 (Moss green)", hex: "2F4538" },
  ],
};

export default ralColors;
