import React, { useContext } from "react";
// eslint-disable-next-line no-unused-vars
import InfoAudioButton from "../InfoAudioButton";
import ProjectContext from "../context/ProjectContext";
import { FlowerBench } from "./sections/FlowerBench";
import { PrivacyScreen } from "./sections/PrivacyScreen";
import { Ladder } from "./sections/Ladder";
import "../App.css";

const P5 = ({ onProjectDataChange }) => {
  const {t} = useContext(ProjectContext);
  return (
    <>
      {/* <div className="row info--button-row">
        <div className="col-xs-12">
          <InfoAudioButton page="P5" />
        </div>
      </div> */}
      <FlowerBench
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
      <PrivacyScreen
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
      <Ladder
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
    </>
  );
};

export default P5;
