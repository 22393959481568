import React, { useContext } from "react";
import ProjectContext from "../context/ProjectContext";
// eslint-disable-next-line no-unused-vars
import InfoAudioButton from "../InfoAudioButton";
import { BalconySize } from "./sections/BalconySize";
import { Supports } from "./sections/Supports";
import { FirstFloorHeight } from "./sections/FirstFloorHeight";
import { Roof } from "./sections/Roof";
import { Warning } from "../components/Warning";
import { BalconiesGap } from "./sections/BalconiesGap";
import { NumberOfBalconies } from "./sections/NumberOfBalconies";

const P1 = ({ onProjectDataChange, sideChanges }) => {
  const { t } = useContext(ProjectContext);
  return (
    <>
      {/* <div className="row info--button-row">
        <div className="col-xs-12">
          <InfoAudioButton page="P1" />
        </div>
      </div> */}

      <BalconySize
        t={t}
        sideChanges={sideChanges}
      />

      <NumberOfBalconies
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
      <FirstFloorHeight
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
      <Supports
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
      <BalconiesGap
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
      <div className="roof-styles">
      <Warning message="Page 5.Roof is not allowed with 0 supports" />
      <Roof onProjectDataChange={onProjectDataChange} />
      </div>
    </>
  );
};

export default P1;
