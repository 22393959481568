import React from 'react'
import './radio-button.css'

export const RadioButton = ({ handleChange, name, disabled  }) => {

  const handleRadioButtonChange = (event) => {
    const value = event.target.value === "true" ? true : false;
    handleChange(value);
  }
  return (
    <div className='col-md-6 radio-button' onChange={handleRadioButtonChange}>
      <input type="radio" value="true" name={name} disabled={disabled} /> Ja
      <input type="radio" value="false" name={name}  disabled={disabled} /> Nein
    </div>
  )
}
