import React, { useContext } from 'react'
import ProjectContext from '../context/ProjectContext';

export const Warning = ({message}) => {

  const { projectData, t } = useContext(ProjectContext);

  return (
    <div
        style={{
          display: projectData.nSupports === 0 ? "block" : "none",
        }}
        className="warning-message-page5"
      >
        {t(message)}
      </div>
  )
}
