import React, { useContext, useState } from 'react'
import Slider from "rc-slider";
import ProjectContext from "../../context/ProjectContext";

export const BalconySize = ({t, sideChanges}) => {
  const {
    projectData,
    setProjectData,
    maxSliderValues,
    minSliderValues,
  } = useContext(ProjectContext);

  const [tempLength, setTempLength] = useState(projectData.length);
  const [tempDepth, setTempDepth] = useState(projectData.depth);

  const getMark = (value) => (
    <div
      style={{
        marginBottom: "-1.5rem",
        marginLeft: "0.5rem",
      }}
    >
      <p style={{ margin: "0px" }}>{value}</p>
      <p style={{ marginTop: "0px" }}>mm</p>
    </div>
  );

  return (
    <>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0 }}>
            {t("Page 1.Select balcony size")}
          </h6>
        </div>
      </div>
      <div className="horizontal-line" style={{ marginTop: "0.6rem" }} />
      <div
        className="row"
        style={{
          marginTop: "1.3rem",
          marginLeft: "0.1rem",
        }}
      >
        <div className="col-xs-2">
          <h6 className="page-title">{t("Page 1.Depth")}</h6>
        </div>
      </div>
      <div
        className="row"
        style={{
          width: "80%",
          marginTop: "1rem",
          marginLeft: "0.1rem",
        }}
      >
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              background: "",
              height: "9rem",
            }}
          >
            <Slider
              min={minSliderValues.depth}
              max={maxSliderValues.depth}
              step={250}
              dots={true}
              vertical={true}
              defaultValue={projectData.depth}
              reverse={true}
              onAfterChange={(value) => {
                if (value !== tempDepth) {
                  setTempDepth(value);
                  sideChanges("depth", value);
                }
              }}
              handleStyle={{
                height: "1.1rem",
                width: "1.1rem",
                marginLeft: -7,
                backgroundColor: "#0070b8",
                border: 0,
              }}
              marks={{
                [projectData.depth]: getMark(projectData.depth),
              }}
              railStyle={{
                width: "0.1rem",
                marginLeft: 1,
              }}
              onChange={(value) =>
                setProjectData({ ...projectData, depth: value })
              }
            />
          </div>
          <div className="page1-box" style={{ marginTop: "2rem" }} />
        </div>
      </div>
      {minSliderValues.length !== maxSliderValues.length && (
        <div
          className="row"
          style={{
            width: "80%",
            marginTop: "1rem",
          }}
        >
          <div className="col-xs-12">
            <h6
              className="page-title"
              style={{ position: "absolute", right: 0 }}
            >
              {t("Page 1.Length")}
            </h6>
          </div>
        </div>
      )}
      <div
        className="row"
        style={{
          width:
            minSliderValues.length === maxSliderValues.length ? "100%" : "80%",
          marginTop: "2rem",
        }}
      >
        <div className="col-xs-12" style={{ textAlign: "center" }}>
          {minSliderValues.length === maxSliderValues.length && (
            <p>
              {" "}
              <mark className="page-title">{`${t("Page 1.Length")}: `}</mark>
              {minSliderValues.length} mm
            </p>
          )}
        </div>

        {minSliderValues.length !== maxSliderValues.length && (
          <>
            <div className="col-xs-2" />
            <div className="col-xs-10">
              <Slider
                min={minSliderValues.length}
                max={maxSliderValues.length}
                step={333}
                dots={true}
                defaultValue={projectData.length}
                onAfterChange={(value) => {
                  if (value !== tempLength) {
                    setTempLength(value);
                    sideChanges("length", value);
                  }
                }}
                handleStyle={{
                  height: "1.1rem",
                  width: "1.1rem",
                  marginLeft: -2,
                  marginTop: -7,
                  backgroundColor: "#0070b8",
                  border: 0,
                }}
                railStyle={{
                  height: "0.1rem",
                  marginLeft: 1,
                }}
                marks={{
                  [projectData.length]: getMark(projectData.length),
                }}
                onChange={(value) =>
                  setProjectData({ ...projectData, length: value })
                }
              />
            </div>
          </>
        )}
      </div>

      <div
        className="message-container"
        style={{
          marginTop:
            minSliderValues.length === maxSliderValues.length ? "10px" : "45px",
        }}
      >
        {t("Page 1.message")}
      </div>
    </>
  )
}
