import React, { useContext } from 'react';
import { RadioButton } from '../../components/RadioButton';
import ProjectContext from '../../context/ProjectContext';

export const FlowerBench = ({ t, onProjectDataChange }) => {

  const {
    projectData,
    setProjectData,
  } = useContext(ProjectContext);

  const handleFlowerBenchChange = (event) => {
    const value = parseInt(event.target.value);
    setProjectData({ ...projectData, folded: value });
    onProjectDataChange({
      profileType: value ? "rounded" : "folded",
    });
  };

  const handleChange = (value) => {
    onProjectDataChange({ flowerBench: value }, 0);
    setProjectData({
      ...projectData,
      flowerBench: value,
    });
  }

  return (
    <>
      <div className="row">
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0, minWidth: "115px" }}>
            {t("Page 6.Flowerbench and baseplate")}
          </h6>
          <RadioButton
            name="Flowerbench and baseplate"
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="horizontal-line" />
      {projectData.flowerBench && (
        <>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-12">
              {projectData.folded === 0 && (
                <img
                  className="responsive-image"
                  src={"/images/P6/Folded.png"}
                  alt="Folded"
                />
              )}
              {projectData.folded === 1 && (
                <img
                  className="responsive-image"
                  src={"/images/P6/Rounded.png"}
                  alt="Rounded"
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12" style={{ textAlign: "center" }}>
              <p className="page-subtitle" style={{ marginBottom: "1rem" }}>
                {t("Page 6.Select baseplate")}
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              textAlign: "center",
              margin: 0,
            }}
          >
            <div>
              <label htmlFor="folded-option" style={{ cursor: "pointer" }}>
                <img
                  src={"/images/P6/angular_base_panel.png"}
                  alt="Angular base panel"
                  style={{
                    height: "auto ",
                    width: "40%",
                    margin: 0,
                    padding: 0,
                  }}
                />

                <input
                  id="folded-option"
                  name="flowerbench-option"
                  type="radio"
                  checked={projectData.folded === 0}
                  value={0}
                  onChange={handleFlowerBenchChange}
                />

                <p
                  className="page-subtitle"
                  style={{
                    marginTop: "1rem",
                    color: projectData.folded === 0 ? "#3f8bb9" : "gray",
                  }}
                >
                  {t("Page 6.Folded")}
                </p>
              </label>
            </div>
            <div>
              <label htmlFor="rounded-option" style={{ cursor: "pointer" }}>
                <img
                  src={"/images/P6/rounded_base_panel.png"}
                  alt="Angular base panel"
                  style={{
                    height: "auto",
                    width: "40%",
                  }}
                />
                <input
                  id="rounded-option"
                  name="flowerbench-option"
                  type="radio"
                  checked={projectData.folded === 1}
                  value={1}
                  onChange={handleFlowerBenchChange}
                />

                <p
                  className="page-subtitle"
                  style={{
                    marginTop: "1rem",
                    color: projectData.folded === 1 ? "#3f8bb9" : "gray",
                  }}
                >
                  {t("Page 6.Rounded")}
                </p>
              </label>
            </div>
          </div>
        </>
      )}
    </>
  )
}
