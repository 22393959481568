import React from "react";
import "./css/back_next_buttons.css";

const BackNextButtons = ({
  nPage,
  handleClickBack,
  handleClickNext,
  handleSummary,
  t,
}) => (
  <div className="back-next--buttons">
    <button
      className="button-back-next"
      style={{
        visibility: nPage === 1 ? "hidden" : "visible",
      }}
      onClick={handleClickBack}
    >
      <div className="button-holder-back">
        <div className="button-triangle--back"></div>
        <div className="button-white--background">{t("Back button")}</div>
      </div>
    </button>

    <button className="summary-button--desktop" onClick={handleSummary}>
      {t("End project")}
    </button>
    <button
      className="button-back-next"
      onClick={handleClickNext}
      style={{ visibility: nPage === 6 ? "hidden" : "visible" }}
    >
      <div className="button-holder-next">
        <div
          className="button-next-white-background"
          style={{
            background: nPage === 6 ? "transparent" : "white",
            color: nPage === 6 ? "white" : "gray",
          }}
        >
          {t("Next button")}
        </div>
        <div className="button-triangle--next"></div>
      </div>
    </button>
  </div>
);

export default BackNextButtons;
