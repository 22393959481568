import React, { useContext } from 'react'
import ItemList from "../../components/ItemList";
import ProjectContext from "../../context/ProjectContext";

const balconyTypeOptions = [
  {
    value: "enclosed",
    label: (
      <img
        className="responsive-image"
        src={"/images/P1/balcony_type1.png"}
        alt="Balcony type 1"
      />
    ),
  },
  {
    value: "left",
    label: (
      <img
        className="responsive-image"
        src={"/images/P1/balcony_type2.png"}
        alt="Balcony type 2"
      />
    ),
  },
  {
    value: "right",
    label: (
      <img
        className="responsive-image"
        src={"/images/P1/balcony_type3.png"}
        alt="Balcony type 3"
      />
    ),
  },
  {
    value: "full",
    label: (
      <img
        className="responsive-image"
        src={"/images/P1/balcony_type4.png"}
        alt="Balcony type 4"
      />
    ),
  },
];

export const BalconyType = ({t, onProjectDataChange}) => {

  const {
    projectData,
    setProjectData,
  } = useContext(ProjectContext);
  
  return (
    <>
      <div className="row" style={{ marginTop: "9px" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0 }}>
            {t("Page 1.Select balcony type")}
          </h6>
        </div>
      </div>
      <div className="horizontal-line" style={{ marginTop: "0.6rem" }} />
      <div className="row" style={{ width: "60%", margin: "auto" }}>
        <ItemList
          items={balconyTypeOptions}
          idPrefix="balconyType"
          onChange={(value) => {
            onProjectDataChange({ wallType: value });
            setProjectData({ ...projectData, balconyType: value });
          }}
          defaultValue={projectData.balconyType}
        />
      </div>
    </>
  )
}
