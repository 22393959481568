import React, { useContext } from 'react';
import ItemList from "../../components/ItemList";
import ProjectContext from "../../context/ProjectContext";

const balustradeTypeOptions = [
  {
    value: "Smilla",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Smila.jpg"}
        alt="Smilla"
      />
    ),
  },
  {
    value: "Clara",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Clara.jpg"}
        alt="Klara"
      />
    ),
  },
  {
    value: "finn",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Finn.jpg"}
        alt="Finn"
      />
    ),
  },
  {
    value: "torge",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Torge.jpg"}
        alt="Torge"
      />
    ),
  },
  {
    value: "dekor",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Mika.jpg"}
        alt="Mika"
      />
    ),
  },
  {
    value: "blench",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Nils.jpg"}
        alt="Nils"
      />
    ),
  },
  {
    value: "rundstabb",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Kulla.jpg"}
        alt="Kulla"
      />
    ),
  },
  {
    value: "ronda",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Ronda.jpg"}
        alt="Ronda"
      />
    ),
  },
  {
    value: "palmo",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Palmo Corten.jpg"}
        alt="Palmo Corten"
      />
    ),
  },
  {
    value: "floral",
    label: (
      <img
        className="responsive-image"
        src={"/images/P4/Palmo.jpg"}
        alt="Palmo"
      />
    ),
  },
];

export const BalustradeType = ({ t, onProjectDataChange }) => {

  const { projectData, setProjectData } = useContext(ProjectContext); 
  return (
    <>
      <div className="row" style={{marginTop: '10px'}}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0 }}>
            {t("Page 4.Select balustrade type")}
          </h6>
        </div>
      </div>
      <div className="horizontal-line" style={{ marginTop: "0.6rem" }} />
      <div style={{ marginTop: "1rem" }} />
      <div className="row" style={{ width: "60%", margin: "auto" }}>
        <ItemList
          items={balustradeTypeOptions}
          idPrefix="balustradeType"
          onChange={(value) => {
            onProjectDataChange({ balustradeType: value }, 0);
            setProjectData({ ...projectData, balustradeType: value });
          }}
          margin={true}
          defaultValue={projectData.balustradeType}
        />
      </div>
    </>
  )
}
