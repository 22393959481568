import React, { useContext } from "react";
import Buttons from "./Buttons";
import ProjectContext from "./context/ProjectContext";
import Title from "./Title"

const Header = ({ headerId }) => {
  const { nPage, setNPage, t } = useContext(ProjectContext);

  return (
    <header id={headerId} className="responsive-header">
      <div id="title-section">
        <Title nPage={nPage} t={t}/>
        <img src={"/images/logo_2023.svg"} alt="Logo" />
      </div>
      <nav id="nav-buttons">
        <Buttons nPage={nPage} setNPage={setNPage} t={t} />
      </nav>
    </header>
  );
};

export default Header;
