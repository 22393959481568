import React, { useContext } from 'react';
import ProjectContext from "../../context/ProjectContext";
import Select from "react-select";

const numberOfBalconiesOptions = [
  { value: "nBalconies2", label: 2 },
  { value: "nBalconies3", label: 3 },
  { value: "nBalconies4", label: 4 },
  { value: "nBalconies5", label: 5 },
  { value: "nBalconies6", label: 6 },
  { value: "nBalconies7", label: 7 },
  { value: "nBalconies8", label: 8 },
  { value: "nBalconies9", label: 9 },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: "center",
  }),
  control: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => {
    return { margin: "auto", color: state.isDisabled ? "gray" : null };
  },
  indicatorSeparator: (provided, state) => ({}),
};



export const NumberOfBalconies = ({t, onProjectDataChange}) => {

  const { projectData, setProjectData } = useContext(ProjectContext);

  function handleNumberOfBalconiesChange(value) {
    onProjectDataChange({ numberFloors: value });
  }

  return (
    <>
     <div className="row" style={{marginTop: '9px'}}>
        <div className="col-xs-12" style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <h6 className="page-title"
              style={{ marginBottom: "0" }}
          >
            {t("Page 3.Select number of balconies")}
          </h6>
        </div>
      </div>
      <div
        className="horizontal-line"
        style={{ marginTop: "0.1rem", marginBottom: "1rem" }}
      />
      <div
        id="number-of-steps"
        className="row"
        style={{ width: "80%", margin: "auto" }} 
      >
        <div className="col-xs-12">
          <Select
            options={numberOfBalconiesOptions}
            defaultValue={numberOfBalconiesOptions[projectData.nBalconies - 1]}
            styles={customStyles}
            isSearchable={false}
            onChange={(value) => {
              handleNumberOfBalconiesChange(value.label);
              setProjectData({ ...projectData, nBalconies: value.label });
            }}
          />
        </div>
      </div>
    </>
  )
}
