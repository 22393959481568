/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from "react";

const sectionsTextArray = [
  "Section Buttons.Page 1",
  "Section Buttons.Page 2",
  "Section Buttons.Page 3",
  "Section Buttons.Page 4",
  "Section Buttons.Page 5",
];

const Buttons = ({ nPage, setNPage, t }) => {
  const handleClick = (index) => {
    setNPage(index + 1);
    document.getElementById('btn-menu').checked = true;
  };

  const buttons = useMemo(
    () =>
      sectionsTextArray.map((text, index) => {
        const isActive = nPage === index + 1;
        const buttonClassName = isActive
          ? "nav-button active-button"
          : "nav-button";
        const textClassName = isActive
          ? "button-text active--button-text"
          : "button-text";

        return (
          <div key={index}>
            <button
              className={buttonClassName}
              onClick={() => handleClick(index)}
            >
              {index + 1}
            </button>
            <p className={textClassName}>{t(text)}</p>
          </div>
        );
      }),

    [nPage]
  );

  return <>{buttons}</>;
};

export default Buttons;
