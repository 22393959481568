/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import ProjectContext from "../context/ProjectContext";
// eslint-disable-next-line no-unused-vars
import InfoAudioButton from "../InfoAudioButton";
import "../App.css";
import { BalconyType } from "./sections/BalconyType";

const P2 = ({ onProjectDataChange }) => {
  const { t } = useContext(ProjectContext);

  return (
    <>
      {/* <div className="row info--button-row">
        <div className="col-xs-12">
          <InfoAudioButton page="P2" />
        </div>
      </div> */}
      <BalconyType
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
    </>
  );
};

export default P2;
