import React, { useContext } from 'react'
import ProjectContext from '../../context/ProjectContext';
import { RadioButton } from '../../components/RadioButton';

export const Ladder = ({ t, onProjectDataChange }) => {

  const { 
    projectData, 
    setProjectData, 
    setMinSliderValues,
    minSliderValues
  } = useContext(ProjectContext);


  const handleChange = (value) => {
    onProjectDataChange({ ladder: value }, 0);
    setProjectData({ ...projectData, ladder: value });
    if (value)
      setMinSliderValues({ ...minSliderValues, length: 3289 });
    else setMinSliderValues({ ...minSliderValues, length: 1957 });
  }

  return (
    <>
      <div className="warning-message-page6">
        <div
          style={{
            display:
              projectData.length < 3289 && !projectData.stairs
                ? "block"
                : "none",
            marginBottom: projectData.stairs ? "1rem" : null,
          }}
        >
          {t("Page 6.The chosen size is too small for this option")}
        </div>
        <div style={{ display: projectData.stairs ? "block" : "none" }}>
          {t("Page 6.ladderWarning")}
        </div>
      </div>
      <div
        className="row"
        style={{
          cursor:
            projectData.length < 3289 || projectData.stairs
              ? "not-allowed"
              : null,
        }}
      >
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h6
            className="page-title"
            style={{
              margin: 0,
              color:
                projectData.length < 3289 || projectData.stairs ? "grey" : null,
              minWidth: "115px"
            }}
          >
            {t("Page 6.Escape ladder")}
          </h6>
          <RadioButton
            name="Escape ladder"
            handleChange={handleChange}
            disabled={
              projectData.length < 3289 || projectData.stairs ? true : false
            }
          />
        </div>
      </div>
      <div className="horizontal-line" />
    </>
  )
}
