import React, { useContext } from 'react'
import ProjectContext from "../../context/ProjectContext";

export const Supports = ({t, onProjectDataChange}) => {

  const { projectData, setMaxSliderValues, setProjectData } = useContext(ProjectContext );

  const handleRadioChange = (event) => {
    const intValue = parseInt(event.target.value);
    const value = event.target.value === "0" ? null : intValue;

    setMaxSliderValues({
      depth: 2250,
      length: 3955,
    });
    onProjectDataChange({ supportType: value });
    setProjectData({ ...projectData, nSupports: intValue });
  };
  return (
    <>
      <div className="row" style={{ marginTop: "9px" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0 }}>
            {t("Page 2.Select number of supports")}
          </h6>
        </div>
      </div>
      <div className="horizontal-line" style={{ marginTop: "0.6rem" }} />
      <label htmlFor="n4">
        <div
          className="row"
          style={{ marginBottom: "2rem", marginTop: "3rem", cursor: "pointer" }}
        >
          <div className="col-xs-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto",
                width: "70%",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <img
                  className="responsive-image"
                  src={"/images/P2/n_supports_4.jpg"}
                  alt="Number of Supports: 4"
                />
              </div>

              <input
                id="n4"
                name="nSupports"
                type="radio"
                checked={projectData.nSupports === 4}
                value={4}
                onChange={handleRadioChange}
                style={{
                  margin: "auto",
                  marginRight: "1rem",
                }}
              />

              <h4
                style={{
                  margin: "auto",
                  color: projectData.nSupports === 4 ? "#3f8bb9" : "gray",
                }}
              >
                4
              </h4>
            </div>
          </div>
        </div>
      </label>
      <label htmlFor="n2">
        <div
          className="row"
          style={{ marginBottom: "2rem", cursor: "pointer" }}
        >
          <div className="col-xs-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto",
                width: "70%",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <img
                  className="responsive-image"
                  src={"/images/P2/n_supports_2.jpg"}
                  alt="Number of Supports: 2"
                />
              </div>
              <input
                id="n2"
                name="nSupports"
                type="radio"
                checked={projectData.nSupports === 2}
                value={2}
                onChange={handleRadioChange}
                style={{
                  margin: "auto",
                  marginRight: "1rem",
                }}
              />
              <h4
                style={{
                  margin: "auto",
                  color: projectData.nSupports === 2 ? "#3f8bb9" : "gray",
                }}
              >
                2
              </h4>
            </div>
          </div>
        </div>
      </label>
      <label htmlFor="n0">
        <div
          className="row"
          style={{ marginBottom: "2rem", cursor: "pointer" }}
        >
          <div className="col-xs-12">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "auto",
                width: "70%",
              }}
            >
              <div style={{ marginRight: "1rem" }}>
                <img
                  className="responsive-image"
                  src={"/images/P2/n_supports_0.jpg"}
                  alt="Number of Supports: 0"
                />
              </div>
              <input
                id="n0"
                name="nSupports"
                type="radio"
                checked={projectData.nSupports === 0}
                value={0}
                onChange={handleRadioChange}
                style={{
                  margin: "auto",

                  marginRight: "1rem",
                }}
              />
              <h4
                style={{
                  margin: "auto",
                  color: projectData.nSupports === 0 ? "#3f8bb9" : "gray",
                }}
              >
                0
              </h4>
            </div>
          </div>
        </div>
      </label>
    </>
  )
}
