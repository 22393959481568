import step1 from './audios/Step 1.mp3';
import step2 from './audios/Step 2.mp3';
import step3 from './audios/Step 3.mp3';
import step4 from './audios/Step 4.mp3';
import step5 from './audios/Step 5.mp3';
import step6 from './audios/Step 6.mp3';
import summary from './audios/Summary.mp3';

export function getInfoAudio(key) {
  switch (key) {
    case 'P1':
      return step1;
    case 'P2':
      return step2;
    case 'P3':
      return step3;
    case 'P4':
      return step4;
    case 'P5':
      return step5;
    case 'P6':
      return step6;
    case 'SUMMARY':
      return summary;
    default:
      return new Error('Object not found');
  }
}