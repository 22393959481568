import React, { useContext } from 'react';
import Select from "react-select";
import { RadioButton } from '../../components/RadioButton';
import ProjectContext from "../../context/ProjectContext";

const numberOfStepsOptions = [
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
    textAlign: "center",
  }),
  control: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => {
    return { margin: "auto", color: state.isDisabled ? "gray" : null };
  },
  indicatorSeparator: (provided, state) => ({}),
};

export const BalconiesGap = ({t, onProjectDataChange}) => {

  const { projectData, setProjectData } = useContext(ProjectContext);



  const handleRadioChange = (event) => {
    setProjectData({ ...projectData, stairsSide: event.target.value });
    onProjectDataChange({ sideStairPosition: event.target.value });
  };

  const handleRadioButton = (value) => {
    onProjectDataChange({ sideStair: value }, 0);
    setProjectData({ ...projectData, stairs:value });
  }

  return (
    <>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div className="col-xs-12" style={{ textAlign: "center" }}>
          <h6 style={{ margin: 0, fontSize: "15px" }}>
            {t("Page 3.height between balconies message")}
          </h6>
        </div>
      </div>
      <div className="row" style={{ marginTop: "1.5rem" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0, minWidth: "115px" }}>
            {t("Page 3.Stairs")}
          </h6>

          <RadioButton
            name="Stairs"
            handleChange={handleRadioButton}
          />
        </div>
      </div>
      <div className="horizontal-line" />
      {projectData.stairs && (
        <>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div
              className="col-xs-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h6 style={{ marginRight: "1rem", color: "gray" }}>
                {t("Page 3.Select side")}
              </h6>
            </div>
          </div>

          <div className="row">
            <div
              className="col-xs-12"
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div>
                <p
                  style={{
                    color:
                      projectData.stairsSide === "left" ? "#3f8bb9" : "gray",
                  }}
                >
                  {t("Page 3.Side A")}
                </p>

                <input
                  id="sideA"
                  name="side"
                  type="radio"
                  value={"left"}
                  onChange={handleRadioChange}
                  checked={projectData.stairsSide === "left"}
                  style={{ cursor: "pointer" }}
                />
              </div>

              <img
                src={"/images/P5/stairs.png"}
                alt="Angular base panel"
                style={{
                  width: "60%",
                  marginRight: "1rem",
                  marginLeft: "1rem",
                }}
              />

              <div>
                <p
                  style={{
                    color:
                      projectData.stairsSide === "right" ? "#3f8bb9" : "gray",
                  }}
                >
                  {t("Page 3.Side B")}
                </p>

                <input
                  id="sideB"
                  name="side"
                  type="radio"
                  value={"right"}
                  onChange={handleRadioChange}
                  checked={projectData.stairsSide === "right"}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>

          <div className="row" style={{ marginTop: "1rem" }}>
            <div
              className="col-xs-12"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <h6
                className="page-title"
                style={{
                  fontSize: "0.9rem",
                  color: projectData.stairs ? "#3f8bb9" : "gray",
                }}
              >
                {t("Page 3.Select number of steps")}
              </h6>
            </div>
          </div>

          <div
            className="row"
            style={{
              marginTop: "1rem",
              width: "80%",
              margin: "auto",
              cursor: !projectData.stairs ? "not-allowed" : null,
            }}
          >
            <div className="col-xs-12">
              <Select
                options={numberOfStepsOptions}
                defaultValue={
                  numberOfStepsOptions[projectData.stairsNumberOfSteps-4]
                }
                styles={customStyles}
                isSearchable={false}
                onChange={(value) => {
                  setProjectData({
                    ...projectData,
                    stairsNumberOfSteps: value.value,
                  });
                  onProjectDataChange({ sideStairSteps: value.value });
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}
