import React, { useContext } from 'react'
import ProjectContext from '../../context/ProjectContext';
import ItemList from '../../components/ItemList';
import { RadioButton } from '../../components/RadioButton';


const roofTypeOptions = [
  {
    value: "balco",
    label: (
      <img
        className="responsive-image"
        src={"/images/P5/roof_type_1.jpg"}
        alt="Roof type 1"
      />
    ),
  },
  {
    value: "glass",
    label: (
      <img
        className="responsive-image"
        src={"/images/P5/roof_type_2.jpg"}
        alt="Roof type 2"
      />
    ),
  },
  {
    value: "white",
    label: (
      <img
        className="responsive-image"
        src={"/images/P5/roof_type_3.jpg"}
        alt="Roof type 3"
      />
    ),
  },
];

export const Roof = ({onProjectDataChange}) => {

  const handleChange = (value) => {
    const newValue = value ? roofTypeOptions[1].value : false;
    onProjectDataChange({ roofType: newValue });
    setProjectData({ ...projectData, roofType: newValue });
  }
  const { projectData, setProjectData, t } = useContext(ProjectContext);
  return (
    <>
      <div
        className="row"
        style={{
          cursor: projectData.nSupports === 0 ? "not-allowed" : null,
        }}
      >
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6
            className="page-title"
            style={{
              margin: 0,
              color: projectData.nSupports === 0 ? "grey" : null,
              minWidth: "115px"
            }}
          >
            {t("Page 5.Roof")}
          </h6>
          <RadioButton
            name="Roof"
            handleChange={handleChange}
            disabled={projectData.nSupports === 0 ? true : false}
          />
        </div>
      </div>
      <div className="horizontal-line" style={{ marginBottom: "1.5rem" }} />
      <div
        className="row"
        style={{
          marginTop: "2rem",
          width: "80%",
          margin: "auto",
          cursor: !projectData.roofType ? "not-allowed" : null,
          display: `${projectData.roofType ? "" : "none"}`,
        }}
      >
        <div className="col-xs-12">
          <ItemList
            items={roofTypeOptions}
            idPrefix="roofType"
            onChange={(value) => {
              onProjectDataChange({ roofType: value }, 0);
              setProjectData({ ...projectData, roofType: value });
            }}
            defaultValue={projectData.roofType}
            disabled={!projectData.roofType}
          />
        </div>
      </div>

    </>
  )
}
