import "./css/app.css";
import "./css/sandmeir.css";
import "./css/parameters.css";
import "./css/nav_buttons.css";
import "./css/summary.css";

import RalColors from "./data/RalColors";
import Summary from "./Summary";
import Requests from "./Counter";
import RestService from "./RestService";
import Parameters from "./Parameters";
import CieloModel from "./CieloModel";
import ProjectContext from "./context/ProjectContext";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import Modal from "./Modal";
import ReactLoading from "react-loading";
import { users, CurrentUser } from "./data/Users";
import {
  product as productDefaultState,
  customerInfo as customerDefaultState,
  additionalProjectInfo as additionalProjectInfoDefaultState,
} from "./state";
import React, { useState, useEffect, useCallback } from "react";

let queriesQueue = [];

const App = () => {
  const { t } = useTranslation("common");

  const [projectData, setProjectData] = useState(productDefaultState);
  const [additionalProjectInfo, setAdditionalProjectInfo] = useState(
    additionalProjectInfoDefaultState
  );

  const [maxSliderValues, setMaxSliderValues] = useState({
    depth: 2250,
    length: 3955,
  });

  const [minSliderValues, setMinSliderValues] = useState({
    length: 1957,
    depth: 1250,
  });

  const [customerInfo, setCustomerInfo] = useState(customerDefaultState);

  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const [loading, setLoading] = useState(true);
  const [nPage, setNPage] = useState(1);
  const [showSummary, setShowSummary] = useState(false);
  const [modal, setModal] = useState(false);
  const [projectId, setProjectId] = useState(-1);
  const [drawingId, setDrawingId] = useState(-1);
  const [depthSides, setDepthSides] = useState([]);
  const [lengthSides, setLengthSides] = useState([]);
  const [projectShareLinkCode, setProjectShareLinkCode] = useState(-1);

  const makeUpdateRequest = (data) => {
    return RestService.request("PUT", `productDefinitions/${drawingId}`, data)
      .then((data) => {
        Requests.counter -= 1;
        // console.log("response", data);
        makeRequests();
      })
      .catch((error) => {
        Requests.counter -= 1;
        // console.log(error);
        makeRequests();
      });
  };

  const getClientPublicIp = useCallback(() => {
    setLoading(true);
    RestService.request("GET", "public_ip")
      .then((data) => {
        const projectName = `${data.ip} ${getCurrentDate()}`;
        // console.log("Project name: ", projectName);
        RestService.request("POST", `projects/copy`, {
          newName: projectName,
        })
          .then((data) => {
            setProjectId(data.project);
            sessionStorage.setItem("projectId", data.project);
          })
          .catch((error) => {
            setLoading(false);
            // console.log(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        // console.log(error);
      });
  }, []);

  const getCurrentDate = () => {
    let date = new Date();

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  const getDrawingId = (id) => {
    RestService.request("GET", `productDefinitions/${id}`)
      .then((data) => {
        setDrawingId(data.result[0].id);
        const parameters = data.result[0].parameters;
        // console.log("Project Data: ", parameters);
        // console.log(data)
        const colorCode = RalColors.colors.findIndex(
          (color) => color.name === parameters.color
        );

        setProjectData({
          ...projectData,
          balconyType: parameters.wallType,
          nSupports: parameters.supportType ? parameters.supportType : 0,
          stairs: parameters.sideStair ? true : null,
          balustradeType: parameters.balustradeType,
          floorColor: parameters.floorType,
          flowerBench: parameters.flowerBench ? true : null,
          folded: parameters.profileType === "rounded" ? 1 : 0,
          ladder: parameters.ladder ? true : null,
          roofType: parameters.roofType ? true : null,
          firstFloorBalcony: parameters.firstFloor ? true : null,
          nBalconies: parameters.numberFloors,
          colorName: parameters.color,
          stairsNumberOfSteps: parameters.sideStairSteps,
          stairsSide: parameters.sideStairPosition,
          color: colorCode,
          blackoutLeft: parameters.blackoutLeft ? true : null,
          blackoutRight: parameters.blackoutRight ? true : null,
        });

        if (parameters.ladder) setMinSliderValues({ length: 389 });
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getSides = (id) => {
    RestService.request("GET", `sides/drawings/${id}`)
      .then((data) => {
        const resultArray = data.result;
        //console.log("loging result ", resultArray);
        const sideArrayLength = resultArray.filter(
          (side) => side.width === 3955
        );
        const sideArrayDepth = resultArray.filter(
          (side) => side.width === 2250
        );
        setLengthSides(sideArrayLength.map((sides) => sides.id));
        setDepthSides(sideArrayDepth.map((sides) => sides.id));
      })
      .catch((error) => {
        // console.error("error on sides: ", error.message);
      });
  };

  useEffect(() => {
    getClientPublicIp();
  }, [getClientPublicIp]);

  useEffect(() => {
    if (projectId !== -1) {
      RestService.request("GET", `drawings/project/${projectId}`)
        .then((data) => {
          const id = data.result[0].id;
          getDrawingId(id);
          getSides(id);
        })
        .catch((error) => {
          // console.error(error.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (drawingId !== -1) {
      // console.log("Project id:", projectId);
      // console.log("Drawing id:", drawingId);

      RestService.request("GET", `sharedLink/${projectId}`)
        .then((data) => {
          setProjectShareLinkCode(data);
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingId]);

  useEffect(() => {
    if (projectShareLinkCode !== -1) setLoading(false);
  }, [projectShareLinkCode]);

  const removeProject = async () => {
    const projectId = sessionStorage.getItem("projectId");
    navigator.sendBeacon(
      `${process.env.REACT_APP_BACK_URL}delete_project/${projectId}`
    );
  };
  useEffect(() => {
    window.addEventListener("unload", removeProject);
    return () => {
      window.removeEventListener("unload", removeProject);
      removeProject();
    };
  }, []);
  const updateCustomerInfo = async () => {
    const customerData = {
      name: `${customerInfo.projectName} - ${customerInfo.company}`,
      customerName: `${customerInfo.name} ${customerInfo.lastName}`,
      customerAddress: customerInfo.address,
      customerPostalCode: customerInfo.postalCode,
      customerCity: customerInfo.city,
      customerPhone: `${customerInfo.mobilePhone} / ${customerInfo.phone}`,
      customerEmail: customerInfo.email,
      customerTitle: customerInfo.title,
      customerCountry: customerInfo.country,
    };

    RestService.request("PUT", `project/${projectId}`, customerData);
  };

  const makeRequests = () => {
    if (queriesQueue.length > 0) {
      const queryData = queriesQueue.shift();
      Requests.counter += 1;
      // console.log("request",queryData)
      if (queryData.sideChanged) {
        requestSideChanges(queryData);
      } else {
        makeUpdateRequest(queryData);
      }
    } else {
      forceUpdate();
    }
  };
  const requestSideChanges = (queryData) => {
    const sideArray =
      queryData.dimension === "depth" ? depthSides : lengthSides;
    const data = {
      data: { width: queryData.value },
      sideArray: sideArray,
    };
    
    RestService.request("POST", "multiple_sides", data)
      .then((data) => {
        Requests.counter -= 1;
        makeRequests();
      })
      .catch((error) => {
        // console.error(error);
        Requests.counter -= 1;
        makeRequests();
      });
  };
  const changeSides = (dimension, value) => {
    if (Requests.counter === 0) {
      Requests.counter += 1;
      forceUpdate();
      requestSideChanges({
        dimension: dimension,
        value: value,
        sideChanged: true,
      });
    } else {
      queriesQueue.push({
        dimension: dimension,
        value: value,
        sideChanged: true,
      });
    }
  };

  const onProjectDataChange = (parameters) => {
    if (Requests.counter === 0) {
      Requests.counter += 1;
      forceUpdate();
      // console.log("request", parameters)
      makeUpdateRequest(parameters);
    } else queriesQueue.push(parameters);
  };
  const translateArray = async (data) => {
    let translatedArray = {};
    Object.keys(data).forEach((key) => {
      translatedArray[t(`Mail.${key}`)] = data[key];
    });
    return translatedArray;
  };
  const sendEmail = async (projectSummaryInfo) => {
    const mountingHeightConverted = parseFloat(additionalProjectInfo.mountingHeight);
    const request = {
      type: "sandMeir/balcony",
      parameters: {
        ...additionalProjectInfo,
        mountingHeight: (isNaN(mountingHeightConverted) ? 0 : mountingHeightConverted) * 1000,
        etics: additionalProjectInfo.etics === "Ja" ? true : false,
        constructionApproval:
          additionalProjectInfo.constructionApproval === "Ja" ? true : false,
        concreteCover:
          additionalProjectInfo.concreteCover === "Ja" ? true : false,
        whatsappCheck:
          additionalProjectInfo.whatsappCheck === "Ja" ? true : false
      },
    };

    await makeUpdateRequest(request);
    const translatedArray = await translateArray({
      ...customerInfo,
      ...additionalProjectInfo,
    });
    const data = {
      projectId: projectId,
      customerInfoTranslated: translatedArray,
      projectData: projectSummaryInfo,
    };
    RestService.request("POST", `send_mail`, {
      ...data,
      servedFrom: CurrentUser.webPage,
      emailAddress: CurrentUser.email,
      projectShareLinkCode: projectShareLinkCode
    });
  };

  const handleClickNext = () => {
    if (nPage === 5) setShowSummary(true);
    else setNPage(nPage + 1);
  };

  const handleClickBack = () => {
    setNPage(nPage - 1);
  };
  const handleSummary = () => {
    setShowSummary(true);
  };
  const showModal = () => {
    setShowSummary(false);
    setModal(true);
    if (CurrentUser.name === users.sandmeir.name) {
      window.open('https://www.sandmeir-metalldesign.de/vielen-dank-fuer-ihre-konfiguratoranfrage/', '_blank').focus();
    }
  };

  const resetState = () => {
    document.location.reload();
  };

  return (
    <ProjectContext.Provider
      value={{
        projectData,
        setProjectData,
        maxSliderValues,
        setMaxSliderValues,
        minSliderValues,
        setMinSliderValues,
        nPage,
        setNPage,
        onProjectDataChange,
        t,
        handleClickBack,
        handleClickNext,
        handleSummary,
      }}
    >
      {showSummary && (
        <div className="summary">
          <Summary
            onClickAccept={() => setShowSummary(false)}
            showModal={() => showModal()}
            projectData={projectData}
            projectId={projectId}
            customerInfo={customerInfo}
            setCustomerInfo={setCustomerInfo}
            updateCustomerInfo={updateCustomerInfo}
            sendEmail={sendEmail}
            additionalProjectInfo={additionalProjectInfo}
            setAdditionalProjectInfo={setAdditionalProjectInfo}
            t={t}
          />
        </div>
      )}
      {!showSummary && (
        <>
          {loading && (
            <div style={{ margin: "auto", height: "100vh" }}>
              <ReactLoading type="spin" color="#0070b8" className="loading" />
            </div>
          )}
          {!loading && (
            <>
              <Header headerId="page-header-small--screen" />
              <div
                id="horizontal-line-small-screen"
                className="horizontal-line"
              ></div>
              <input type="checkbox" id="btn-menu" />

              <div id="main-content">
                <nav className="button--nav">
                  <label className="btn-logo " htmlFor="btn-menu">
                    <div
                      className="nav--button"
                      id="show-hide-button"
                      data-edit-text="Edit"
                      data-see3d-text="See 3D"
                    >
                      <p className="button--text show-hide--edit">
                        {t("Edit")}
                      </p>
                      <p className="button--text show-hide--see">
                        {t("See 3d")}
                      </p>
                    </div>
                  </label>
                  <div
                    className="nav--button summary--button"
                    onClick={handleSummary}
                  >
                    <p className="button--text">{t("End project")}</p>
                  </div>
                </nav>
                <Parameters sideChanges={changeSides} />
                <div id="model-container">
                  <div className="vertical-line" />
                  <div>
                    <Header headerId="page-header-large--screen" />
                    <div
                      id="horizontal-line-large-screen"
                      className="horizontal-line"
                    ></div>
                    <CieloModel projectShareLinkCode={projectShareLinkCode} />
                  </div>
                </div>
              </div>
              {modal && <Modal t={t} resetState={resetState} />}
            </>
          )}
        </>
      )}
    </ProjectContext.Provider>
  );
};

export default App;
