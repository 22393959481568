import React, { useContext } from "react";
import ProjectContext from "../context/ProjectContext";
// eslint-disable-next-line no-unused-vars
import InfoAudioButton from "../InfoAudioButton";
import { BalustradeType } from "./sections/BalustradeType";
import "../App.css";

const P3 = ({ onProjectDataChange }) => {
  const { t } = useContext(ProjectContext);

  return (
    <>
      {/* <div className="row info--button-row">
        <div className="col-xs-12">
          <InfoAudioButton page="P3" />
        </div>
      </div> */}
      <BalustradeType
        t={t}
        onProjectDataChange={onProjectDataChange}
      />
    </>
  );
};

export default P3;
