/* eslint-disable no-restricted-globals */
import React from "react";
import { CurrentUser } from "./data/Users";

/**
 * Service for Rest requests
 *
 * @class RestService
 * @extends {React.Component}
 */
class RestService extends React.Component {
  /**
   * Check response status as Fetch
   *
   * @param {any} response
   * @returns
   */
  checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }

  /**
   *
   *
   * @param {Object} response
   * @returns Object
   */
  parseJSON(response) {
    return response.json();
  }

  sendRequest(url, options) {
    return fetch(url, options)
      .then(this.checkStatus)
      .then(this.parseJSON)
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        if (error.response === undefined) {
          error.response = {
            status: 500,
            message: "Failed to connect server",
          };
        } else if (error.response.status === 401) {
        }

        throw error.response;
      });
  }

  buildUrl(url) {
    const queryString = `?user=${CurrentUser.name}`;

    return process.env.REACT_APP_BACK_URL + url + queryString;
  }

  /**
   *
   *
   * @param {Object} data
   * @returns Object
   */
  resolveParameters(data) {
    let query = "";
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        query += `${this.resolveParametersFromArray(key, value)}`;
      } else {
        query += `&${key}=${value}`;
      }
    }
    return query;
  }

  resolveParametersFromArray(key, value) {
    let query = "";
    value.map((value) => (query += `&${key}%5B%5D=${value}`));
    return query;
  }

  getOptions(nocache, type) {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json; charset=UTF-8");

    if (nocache === true) {
      myHeaders.append("Cache-Control", "no-cache, no-store");
    }
    return {
      method: type,
      mode: "cors",
      headers: myHeaders,
    };
  }

  /**
   * Make reqular json request
   *
   * @param {String} type
   * @param {String} url
   * @param {Object} data
   * @returns Object
   */
  request(type, url, data, nocache = true) {
    url = this.buildUrl(url);
    let options = this.getOptions(nocache, type);

    if (data !== null) {
      if (type === "POST" || type === "PUT") {
        data = JSON.stringify(data);
        options.body = data;
      }
    }
    //console.log("URL", url)
    return this.sendRequest(url, options);
  }
}

export default new RestService();
