import "./css/infoAudio.css";
import React, { useState, useEffect } from "react";
import { getInfoAudio } from "./data/InfoAudio";
const InfoAudioButton = ({page}) => {
  const [audio, setAudio] = useState(undefined);
  const [isPLaying, setplaying] = useState(false);
  useEffect(() => {
    setAudio(document.getElementById("audio"));
  }, []);

  const togglePlay = () => {
    setplaying(!isPLaying);
    if (isPLaying) {
      audio.pause();
    } else {
      audio.currentTime = 0;
      audio.play();
    } 
  }

  return(
    <div>
      <audio id="audio">
        <source src={getInfoAudio(page)} type="audio/mpeg"/>
      </audio>
      <button 
        onClick={togglePlay}
        className="info-button"
      >
        <img src={"/images/sound.svg"} alt="sound icon" />
        <p  className="info-text"> Hilfe </p>
      </button>
    </div>
    
  );
}

export default InfoAudioButton;