import React, { useContext } from 'react';
import Select from "react-select";
import ProjectContext from "../../context/ProjectContext";
import RalColors from "../../data/RalColors";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  menu: (provided, state) => ({
    ...provided,
  }),
  control: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => {},
  indicatorSeparator: (provided, state) => ({}),
};

export const Color = ({ onProjectDataChange }) => {


  const { projectData, setProjectData, t } = useContext(ProjectContext);

  const colorOptions = RalColors.colors.map((color, index) => ({
    value: [color.code, index],
    label: (
      <div className="flex-container">
        <div
          className="color-box"
          style={{
            background: `#${color.hex}`,
          }}
        />
        <div className="color-name">{t(`Page 4.colors.${color.code}`)}</div>
      </div>
    ),
  }));

  const handleRadioChange = (event) => {
    onProjectDataChange({ floorType: event.target.value }, 0);
    setProjectData({ ...projectData, floorColor: event.target.value });
  };

  return (
    <>
      <div className="row" style={{ width: "85%", margin: "auto" }}>
        <div className="col-xs-12">
          <Select
            options={colorOptions}
            defaultValue={colorOptions[projectData.color]}
            styles={customStyles}
            isSearchable={false}
            onChange={(value) => {
              onProjectDataChange({ color: value.value[0] }, 0);
              setProjectData({
                ...projectData,
                color: value.value[1],
                colorName: RalColors.colors[value.value[1]].name,
              });
            }}
          />
        </div>
      </div>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div className="col-xs-12" style={{ textAlign: "center" }}>
          <h6 className="page-title">{t("Page 4.Select floor color")}</h6>
        </div>
      </div>

      <div className="horizontal-line" />

      <div
        className="row"
        style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}
      >
        <div className="col-xs-5">
          <label htmlFor="n1" style={{ margin: 0 }}>
            <img
              className="responsive-image"
              src={"/images/P4/texture1.jpg"}
              alt="Floor color 1"
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            id="n1"
            name="texture"
            type="radio"
            checked={projectData.floorColor === "SA0003"}
            value={"SA0003"}
            style={{
              position: "absolute",
              bottom: "0.5rem",
              right: "1.7rem",
              cursor: "pointer",
            }}
            onChange={handleRadioChange}
          />
        </div>
        <div
          className="col-xs-5"
          style={{ marginRight: "0.2rem", width: "100%" }}
        >
          <label htmlFor="n2" style={{ margin: 0 }}>
            <img
              className="responsive-image"
              src={"/images/P4/texture2.jpg"}
              alt="Floor color 2"
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            id="n2"
            name="texture"
            type="radio"
            checked={projectData.floorColor === "SA0001"}
            value={"SA0001"}
            style={{
              position: "absolute",
              bottom: "0.5rem",
              right: "1.7rem",
              cursor: "pointer",
            }}
            onChange={handleRadioChange}
          />
        </div>
      </div>
      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xs-5">
          <p
            style={{
              fontSize: "0.8rem",
              color: projectData.floorColor === "SA0003" ? "#3f8bb9" : "gray",
            }}
          >
            {t("Page 4.Granit dunkel")}
          </p>
        </div>
        <div className="col-xs-5">
          <p
            style={{
              fontSize: "0.8rem",
              color: projectData.floorColor === "SA0001" ? "#3f8bb9" : "gray",
            }}
          >
            {t("Page 4.Granit hell")}
          </p>
        </div>
      </div>
      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xs-5">
          <label htmlFor="n3" style={{ margin: 0 }}>
            <img
              className="responsive-image"
              src={"/images/P4/texture3.jpg"}
              alt="Floor color 1"
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            id="n3"
            name="texture"
            type="radio"
            checked={projectData.floorColor === "SA0002"}
            value={"SA0002"}
            style={{
              position: "absolute",
              bottom: "0.5rem",
              right: "1.7rem",
              cursor: "pointer",
            }}
            onChange={handleRadioChange}
          />
        </div>
        <div className="col-xs-5">
          <label htmlFor="n4" style={{ margin: 0 }}>
            <img
              className="responsive-image"
              src={"/images/P4/texture4.jpg"}
              alt="Floor color 2"
              style={{ cursor: "pointer" }}
            />
          </label>
          <input
            id="n4"
            name="texture"
            type="radio"
            checked={projectData.floorColor === "SA0004"}
            value={"SA0004"}
            style={{
              position: "absolute",
              bottom: "0.5rem",
              right: "1.7rem",
              cursor: "pointer",
            }}
            onChange={handleRadioChange}
          />
        </div>
      </div>

      <div
        className="row"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div className="col-xs-5">
          <p
            style={{
              fontSize: "0.8rem",
              color: projectData.floorColor === "SA0002" ? "#3f8bb9" : "gray",
            }}
          >
            {t("Page 4.Granit gelb")}
          </p>
        </div>
        <div className="col-xs-5">
          <p
            style={{
              fontSize: "0.8rem",
              color: projectData.floorColor === "SA0004" ? "#3f8bb9" : "gray",
            }}
          >
            {t("Page 4.Granit braun")}
          </p>
        </div>
      </div>
    </>
  )
}
