import React, { useMemo } from "react";

const Title = ({ nPage, t }) => {
  const text = useMemo(() => {
    if (nPage === 1) return "Section Subtitles.Page 1";
    if (nPage === 2) return "Section Subtitles.Page 2";
    if (nPage === 3) return "Section Subtitles.Page 3";
    if (nPage === 4) return "Section Subtitles.Page 4";
    if (nPage === 5) return "Section Subtitles.Page 5";
    if (nPage === 6) return "Section Subtitles.Page 6";
  }, [nPage]);

  return (
    <div className="title-holder">
      <h1 className="main-title">{t("Main title")}</h1>
      <h4 className="subtitle">{t(text)}</h4>
    </div>
  );
};

export default Title;
