import React, { useContext, useState } from 'react';
import ProjectContext from '../../context/ProjectContext';
import { RadioButton } from '../../components/RadioButton';

export const PrivacyScreen = ({ t, onProjectDataChange }) => {

  const { projectData, setProjectData } = useContext(ProjectContext);
  const [privacyScreen, setPrivacyScreen] = useState(
    projectData.blackoutLeft === null && projectData.blackoutRight === null
      ? null
      : projectData.blackoutLeft || projectData.blackoutRight
  );

  const disableBothBlackouts = () => {
    onProjectDataChange({ blackoutLeft: false, blackoutRight: false });
    setProjectData({
      ...projectData,
      blackoutLeft: false,
      blackoutRight: false,
    });
  };

  const handleCheckboxChange = (value) => {
    if (value === "left") {
      onProjectDataChange({ blackoutLeft: !projectData.blackoutLeft });
      setProjectData({
        ...projectData,
        blackoutLeft: !projectData.blackoutLeft,
      });
    } else {
      onProjectDataChange({ blackoutRight: !projectData.blackoutRight });
      setProjectData({
        ...projectData,
        blackoutRight: !projectData.blackoutRight,
      });
    }
  };

  const handleChange = (value) => {
    if (!value) {
      if (projectData.blackoutLeft && projectData.blackoutRight)
        disableBothBlackouts();
      else if (projectData.blackoutLeft) handleCheckboxChange("left");
      else if (projectData.blackoutRight)
        handleCheckboxChange("right");
    }
    setPrivacyScreen(value);
  }

  return (
    <>
      <div className="row" style={{ marginTop: "2rem" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h6 className="page-title" style={{ margin: 0, minWidth: "115px" }}>
            {t("Page 6.Privacy screen")}
          </h6>

          <RadioButton
            name="Privacy screen"
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="horizontal-line" />
      {privacyScreen && (
        <div className="row">
          <div
            className="col-xs-12"
            style={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <p
                style={{
                  color: projectData.blackoutLeft ? "#3f8bb9" : "gray",
                }}
              >
                {t("Page 6.Side A")}
              </p>

              <input
                id="sideA"
                name="side"
                type="checkbox"
                value={"left"}
                checked={projectData.blackoutLeft}
                onChange={(event) => {
                  handleCheckboxChange(event.target.value);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>

            <img
              src={"/images/P6/privacy_screen.png"}
              alt="Angular base panel"
              style={{
                width: "60%",
                marginRight: "1rem",
                marginLeft: "1rem",
              }}
            />

            <div>
              <p
                style={{
                  color: projectData.blackoutRight ? "#3f8bb9" : "gray",
                }}
              >
                {t("Page 6.Side B")}
              </p>

              <input
                id="sideB"
                name="side"
                type="checkbox"
                value={"right"}
                checked={projectData.blackoutRight}
                onChange={(event) => {
                  handleCheckboxChange(event.target.value);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </div>
      )}

    </>
  )
}
