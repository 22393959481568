/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useCallback } from "react";
import ProjectContext from "../context/ProjectContext";

import P1 from "./P1";
import P2 from "./P2";
import P3 from "./P3";
import P4 from "./P4";
import P5 from "./P5";
import BackNextButtons from "../BackNextButtons";

const Parameters = ({ sideChanges }) => {
  const {
    nPage,
    t,
    onProjectDataChange,
    handleClickBack,
    handleClickNext,
    handleSummary,
  } = useContext(ProjectContext);

  const handleParameterChange = useCallback(
    (parameters) => {
      const data = {
        type: "sandMeir/balcony",
        parameters: {
          ...parameters,
        },
      };

      onProjectDataChange(data);
    },
    [onProjectDataChange]
  );

  const page = useMemo(() => {
    if (nPage === 1)
      return (
        <P1
          onProjectDataChange={handleParameterChange}
          sideChanges={sideChanges}
          t={t}
        />
      );
    if (nPage === 2)
      return <P2 onProjectDataChange={handleParameterChange} t={t} />;
    if (nPage === 3)
      return <P3 onProjectDataChange={handleParameterChange} t={t} />;
    if (nPage === 4)
      return <P4 onProjectDataChange={handleParameterChange} t={t} />;
    if (nPage === 5)
      return <P5 onProjectDataChange={handleParameterChange} t={t} />;
  }, [nPage]);

  return (
    <div className="parameters--position">
      <BackNextButtons
        nPage={nPage}
        handleClickBack={handleClickBack}
        handleClickNext={handleClickNext}
        handleSummary={handleSummary}
        t={t}
      />
      <section id="scrollable-parameters--container" className="parameters">
        <div id="parameters-container">
          {page}
        </div>
      </section>
    </div>
  );
};

export default Parameters;
