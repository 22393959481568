import React from "react";
import ReactLoading from "react-loading";
import Requests from "./Counter";
import "./App.css";

const CieloModel = ({ projectShareLinkCode }) => {
  return (
    <>
      {Requests.counter > 0 && (
        <div style={{width: 'fit-content', margin: '0 auto', marginTop: '300px'}}>
          <ReactLoading
            type="spin"
            color="#0070b8"
          />
        </div>
      )}
      {Requests.counter === 0 && (
        <iframe
          id="cielo-iframe"
          src={`${process.env.REACT_APP_IFRAME_URL}/3d_local/de?s=${projectShareLinkCode}&loc=13.52%2C6.57%2C9.47&look=0.00%2C0.00%2C0.00&header=0&controls=0&edges=0&lang=de`}
          title="Generated 3D Balcony Model"
        />
      )}
    </>
  );
};

export default CieloModel;
