import React, { useContext } from "react";
import ProjectContext from "../context/ProjectContext";
// eslint-disable-next-line no-unused-vars
import InfoAudioButton from "../InfoAudioButton";
import { Color } from "./sections/Color";
import "../App.css";


const P4 = ({ onProjectDataChange }) => {
  const { t } = useContext(ProjectContext);

  return (
    <>
      {/* <div className="row info--button-row">
        <div className="col-xs-12">
          <InfoAudioButton page="P4" />
        </div>
      </div> */}
      <div className="row" style={{ marginTop: "2rem" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <h6 className="page-title" style={{ margin: 0 }}>
            {t("Page 4.Select handrail & columns color")}
          </h6>
        </div>
      </div>

      <div className="horizontal-line" style={{ marginTop: "0.6rem" }} />

      <div style={{ marginTop: "1rem" }} />

     <Color onProjectDataChange={onProjectDataChange}/>
    </>
  );
};

export default P4;
