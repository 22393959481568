export const product = {
  balconyType: "enclosed",
  depth: 2250,
  length: 3955,
  nSupports: 4,
  nBalconies: 1,
  firstFloorBalcony: null,
  stairs: null,
  stairsSide: null,
  stairsNumberOfSteps: 5,
  balustradeType: "",
  color: 0,
  floorColor: "",
  roofType: null,
  flowerBench: null,
  folded: 0,
  blackoutLeft: null,
  blackoutRight: null,
  ladder: null,
  colorName: "",
}

export const customerInfo = {
    mrMrs: "",
    name: "",
    lastName: "",
    company: "",
    email: "",
    mobilePhone: "",
    phone: "",
    address: "",
    postalCode: "",
    city: "",
    projectName: "",
    country: "",
    newsletter: "",
}

export const additionalProjectInfo = {
  etics: null,
  constructionApproval: null,
  concreteCover:  null,
  mountingHeight: "",
  yearOfConstruction: "",
  UserComments: "",
  whatsappCheck: "Nein"
}