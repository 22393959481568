const searchParams = new URLSearchParams(window.location.search);
const user = searchParams.get("user");

export const users = {
  sandmeir: {
    name: "SANDMEIR",
    webPage: "https://sandmeir.de",
    dataProtectionLink: "https://sandmeir.de/datenschutzerklaerung",
    email: "cielo@sandmeir.de",
  },
  balkonfuchs: {
    name: "BALKONFUCHS",
    webPage: "https://www.balkonfuchs.de",
    dataProtectionLink:
      "https://www.balkonfuchs.de/Fuchsbau/Impressum/datenschutz",
    email: "mb@balkonfuchs.de",
  },
  gaebert: {
    name: "GAEBERT",
    webPage: "https://www.gaebert.works",
    dataProtectionLink: "https://www.gaebert.works/recht/datenschutz",
    email: "balkonraum@gaebert.works",
  },
  steeltec: {
    name: "STEELTEC",
    webPage: "https://www.steeltec-stahlbau.com",
    dataProtectionLink: "https://www.steeltec-stahlbau.com/datenschutz",
    email: "info@steeltec-stahlbau.com",
  },
  crstahlbau: {
    name: "CRSTAHLBAU",
    webPage: "https://www.crstahlbau.de",
    dataProtectionLink: "https://www.crstahlbau.de/Datenschutz",
    email: "carsten.ritter@crstahlbau.de",
  },
  moers: {
    name: "MOERS",
    webPage: "https://www.stahlbau-moers.de",
    dataProtectionLink: "https://www.stahlbau-moers.de/datenschutz",
    email: "info@metalltechnik-moers.de",
  },
  schulze: {
    name: "SCHULZE",
    webPage: "https://www.metallundraum.de",
    dataProtectionLink: "https://www.metallundraum.de",
    email: "schulze@metallbaumeister.com",
  },
  selz: {
    name: "SELZ",
    webPage: "https://metallbauelemente-selz.de",
    dataProtectionLink: "https://www.metallbauelemente-selz.de/datenschutz.html",
    email: "info@mb-metallbauelemente.de",
  },
  dasbach: {
    name: "DASBACH",
    webPage: "https://www.dasbach-gmbh.de",
    dataProtectionLink: "https://dasbach-gmbh.de/datenschutzerklaerung",
    email: "info@dasbach.net",
  },
  jankowski: {
    name: "JANKOWSKI",
    webPage: "https://jankowski-metallbau.de",
    dataProtectionLink: "https://jankowski-metallbau.de/datenschutz",
    email: "info@jankowski-metallbau.de",
  },
  wohnungen: {
    name: "WOHNUNGEN",
    webPage: "https://www.ddwohnungen.de/",
    dataProtectionLink: "https://sandmeir.de/datenschutzerklaerung",
    email: "info@balkonien.shop",
  },
  seidl: {
    name: "SEIDL",
    webPage: "https://www.seidlmetall.de/",
    dataProtectionLink: "https://www.seidlmetall.de/j/privacy",
    email: "info@seidl-johann.de",
  },
  mantec: {
    name: "MANTEC",
    webPage: "https://www.mantec-metallbau.de",
    dataProtectionLink: "https://www.mantec-metallbau.de/datenschutz",
    email: "info@mantec-metallbau.de",
  },
  landar: {
    name: "LANDAR",
    webPage: "https://www.metallbau-landar.de",
    dataProtectionLink: "https://www.metallbau-landar.de/datenschutz",
    email: "info@metallbau-landar.de",
  },
};

export const CurrentUser = users[user] || users.sandmeir;
