/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import InfoAudioButton from "./InfoAudioButton";
import RalColors from "./data/RalColors";
import { CurrentUser } from "./data/Users";

const InputField = ({ title, id, required, value, setValue, type }) => (
  <div className="field--margin">
    <h6 className="page-title">
      {title}
      {required && (
        <span style={{ color: "red", display: "inline-block" }}>*</span>
      )}
    </h6>
    <input
      type={type}
      style={{
        width: "100%",
        border: "solid 1px",
        height: "2.2rem",
      }}
      value={value}
      onChange={(event) => setValue(event.target.value)}
      id={id}
      required={required}
    />
  </div>
);

const DropDownList = ({ title, required, value, setValue, placeholder }) => (
  <div className="field--margin">
    <h6 className="page-title">
      {title}
      {required && <p style={{ color: "red", display: "inline-block" }}>*</p>}
    </h6>

    <select
      className={`form-control customized-select--summary ${
        value === null ? "select-default--summary" : ""
      }`}
      name={title}
      required={required}
      onChange={(e) => {
        e.persist();
        setValue(e.target.value);
      }}
      value={value}
    >
      <option value="" selected disabled>
        {placeholder}
      </option>
      <option value="Ja">Ja</option>
      <option value="Nein">Nein</option>
    </select>
  </div>
);

InputField.defaultProps = {
  required: true,
  type: "text",
};

const SummaryField = ({ title, text, subTitles }) => {
  let subFields = null;
  if (subTitles) {
    subFields = subTitles.map((parameter, index) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        key={index}
      >
        <div
          style={{ display: "flex", flexDirection: "row", marginLeft: "2rem" }}
        >
          <span className="dot" style={{ marginTop: "0.5rem" }}></span>

          <p style={{ marginLeft: "1rem", color: "gray" }}>{parameter.title}</p>
        </div>

        <p style={{ color: "gray" }}>{parameter.text}</p>
      </div>
    ));
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h5 className="page-title">{title}</h5>
        <p>{text}</p>
      </div>

      <div
        className="horizontal-line"
        style={{ width: "100%", marginBottom: "0.5rem" }}
      />
      {subFields}
    </div>
  );
};

const floorColorMap = {
  SA0003: "Granit dunkel",
  SA0001: "Granit hell",
  SA0002: "Granit gelb",
  SA0004: "Granit braun",
};

const Summary = ({
  onClickAccept,
  showModal,
  projectData,
  additionalProjectInfo,
  setAdditionalProjectInfo,
  customerInfo,
  setCustomerInfo,
  updateCustomerInfo,
  sendEmail,
  t,
}) => {
  const [projectSummaryInfo, setProjectSummaryInfo] = useState([]);

  useEffect(() => {
    const projectSummaryInfoModel = [
      { title: t("Summary.Balcony type"), text: "Balcony type reference" },
      { title: t("Summary.Balcony size"), text: "0000mm x 0000mm" },
      { title: t("Summary.Number of supports"), text: "4" },
      { title: t("Summary.Number of balconies"), text: "#" },
      {
        title: t("Summary.First floor balcony"),
        text: "Yes",
        subTitlesYes: [
          {
            title: t("Summary.Floor to first balcony height (H1)"),
            text: "600mm",
          },
          { title: t("Summary.Height between balconies (H2)"), text: "2800mm" },
        ],
        subTitlesNo: [
          { title: t("Summary.Height between balconies (H2)"), text: "2800mm" },
        ],
      },
      {
        title: t("Summary.Stairs"),
        text: "Yes",
        subTitlesYes: [{ title: t("Summary.Number of stairs"), text: "#" }],
      },
      {
        title: t("Summary.Balustrade type"),
        text: "Balustrade type reference",
      },
      { title: t("Summary.Handrail/column color"), text: "Ral color referene" },
      { title: t("Summary.Floor color"), text: "floor color reference" },
      {
        title: t("Summary.Roof"),
        text: "yes",
        subTitlesYes: [
          { title: t("Summary.Roof type"), text: "Roof reference" },
        ],
      },
      {
        title: t("Summary.Flowerbench"),
        text: "Yes",
        subTitlesYes: [{ title: t("Summary.Baseplate"), text: "Folded" }],
      },
      { title: t("Summary.Privacy screen"), text: "Yes - Both sides" },
      { title: t("Summary.Escape ladder"), text: "Yes" },
    ];

    const yes = t("Yes");
    const no = t("No");

    const info = [...projectSummaryInfoModel];

    info[0].text = t(`Page 1.${projectData.balconyType}`);
    info[1].text = `${projectData.depth}mm x ${projectData.length}mm`;
    info[2].text = `${projectData.nSupports}`;
    info[3].text = `${projectData.nBalconies}`;
    info[4].text = projectData.firstFloorBalcony ? yes : no;

    info[4].subTitles = projectData.firstFloorBalcony
      ? [...info[4].subTitlesYes]
      : [...info[4].subTitlesNo];

    info[5].text = projectData.stairs ? yes : no;
    if (projectData.stairs) {
      info[5].subTitles = [...projectSummaryInfoModel[5].subTitlesYes];
      info[5].subTitles[0].text = `${projectData.stairsNumberOfSteps}`;
    }
    info[6].text = t(`Balustrade.${projectData.balustradeType}`);
    const color = RalColors.colors.find(
      (color) => color.name === projectData.colorName
    );
    info[7].text = color ? t(`Page 4.colors.${color.code}`) : "";
    info[8].text = `${floorColorMap[projectData.floorColor]}`;
    info[9].text =
      projectData.roofType && projectData.nSupports !== 0 ? yes : no;
    if (projectData.roofType && projectData.nSupports !== 0) {
      info[9].subTitles = info[9].subTitlesYes;
      info[9].subTitles[0].text = `${projectData.roofType}`;
    }
    info[10].text = projectData.flowerBench ? yes : no;
    if (projectData.flowerBench) {
      info[10].subTitles = info[10].subTitlesYes;
      info[10].subTitles[0].text =
        projectData.folded === 0 ? t("Page 6.Folded") : t("Page 6.Rounded");
    }

    let privacySide = "";
    const privacySc = projectData.blackoutLeft || projectData.blackoutRight;
    const privacySCBoth = projectData.blackoutLeft && projectData.blackoutRight;

    if (privacySCBoth) privacySide = t("Page 6.Both sides");
    else if (projectData.blackoutRight) privacySide = t("Page 6.Side B");
    else if (projectData.blackoutLeft) privacySide = t("Page 6.Side A");

    info[11].text = privacySc ? `${yes} - ${privacySide}` : no;
    info[12].text = projectData.ladder && !projectData.stairs ? yes : no;
    setProjectSummaryInfo(info);
  }, []);

  const summaryFields = useMemo(() => {
    const records = projectSummaryInfo.map((parameter, index) => (
      <SummaryField
        title={parameter.title}
        text={parameter.text}
        subTitles={parameter.subTitles}
        key={index}
      />
    ));

    return (
      <div className="row">
        <div
          className="col-xs-12"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {records}
        </div>
      </div>
    );
  }, [projectSummaryInfo]);

  const mySubmitFunction = async (e) => {
    e.preventDefault();
    await updateCustomerInfo();
    // debugger;
    await sendEmail(projectSummaryInfo);
    showModal();
    return false;
  };

  return (
    <>
      <div className="row" style={{ marginBottom: "2rem" }}>
        <div className="col-xs-12"
             style={{ 
               display: "flex",
               justifyContent: "space-between",
            }}
        >
          <div style={{ alignSelf: "flex-end" }}>
            <InfoAudioButton page="SUMMARY" />
          </div>
          <button
            style={{
              border: 0,
              padding: 0,
              backgroundColor: "transparent",
              outline: "none",
              margin: "auto",
              float: "right",
              marginRight: "1rem",
            }}
            onClick={onClickAccept}
          >
            <div
              style={{
                backgroundColor: "#0070b8",
                padding: "0.2rem",
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
              }}
            >
              <div
                style={{
                  color: "white",
                  paddingRight: "1rem",
                  fontSize: "1.5rem",
                }}
              >
                X
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="row" style={{ padding: "32px" }}>
        <div className="col-xs-12">
          <div className="field--margin">
            <h4 className="page-title">{t("Summary.User Comments")}</h4>
            <textarea
              id="userComments"
              name="userComments"
              style={{
                width: "100%",
                border: "solid 1px",
                height: "7rem",
              }}
              onChange={(event) =>
                setAdditionalProjectInfo({
                  ...additionalProjectInfo,
                  UserComments: event.target.value,
                })
              }
            >
              {additionalProjectInfo.UserComments}
            </textarea>
          </div>
        </div>
      </div>
      <form
        onSubmit={(e) => mySubmitFunction(e)}
        name="client-info"
      >
        <div style={{ padding: "32px" }}>
          <div className="row">
            <div className="col-xs-12">
              <h3 className="page-title">{t("Summary.User information")}</h3>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-xs-12 horizontal-line" />
          </div>
          <div className="row" style={{ marginTop: "2rem" }}>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Mr / Mrs")}
                id="customerTitle"
                value={customerInfo.mrMrs}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, mrMrs: newValue })
                }
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Name")}
                id="customerName"
                value={customerInfo.name}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, name: newValue })
                }
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Last name")}
                id="customerLastName"
                value={customerInfo.lastName}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, lastName: newValue })
                }
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Company")}
                id="customerCompany"
                value={customerInfo.company}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, company: newValue })
                }
                required={false}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <InputField
                title={"E-mail"}
                type="email"
                id="customerEmail"
                value={customerInfo.email}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, email: newValue })
                }
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Mobile phone")}
                id="customerPhone"
                value={customerInfo.mobilePhone}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, mobilePhone: newValue })
                }
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Phone")}
                id="customerTel"
                value={customerInfo.phone}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, phone: newValue })
                }
                required={false}
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Address")}
                id="customerAddress"
                value={customerInfo.address}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, address: newValue })
                }
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Postal code")}
                type="text"
                id="customerPostalCode"
                value={customerInfo.postalCode}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, postalCode: newValue })
                }
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.City")}
                id="customerCity"
                value={customerInfo.city}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, city: newValue })
                }
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Project name")}
                id="customerProjectName"
                value={customerInfo.projectName}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, projectName: newValue })
                }
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Country")}
                id="customerCountry"
                value={customerInfo.country}
                setValue={(newValue) =>
                  setCustomerInfo({ ...customerInfo, country: newValue })
                }
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <DropDownList
                title={t("Summary.Etics")}
                placeholder={t("Select")}
                required={true}
                value={additionalProjectInfo.etics}
                setValue={(newValue) =>
                  setAdditionalProjectInfo({
                    ...additionalProjectInfo,
                    etics: newValue,
                  })
                }
              />
            </div>
            <div className="col-xs-6">
              <DropDownList
                title={t("Summary.Concrete cover")}
                placeholder={t("Select")}
                required={true}
                value={additionalProjectInfo.concreteCover}
                setValue={(newValue) =>
                  setAdditionalProjectInfo({
                    ...additionalProjectInfo,
                    concreteCover: newValue,
                  })
                }
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <DropDownList
                title={t("Summary.Construction approval")}
                placeholder={t("Select")}
                required={true}
                value={additionalProjectInfo.constructionApproval}
                setValue={(newValue) =>
                  setAdditionalProjectInfo({
                    ...additionalProjectInfo,
                    constructionApproval: newValue,
                  })
                }
              />
            </div>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Mounting height")}
                id="projectmountingHeight"
                value={additionalProjectInfo.mountingHeight}
                setValue={(newValue) =>
                  setAdditionalProjectInfo({
                    ...additionalProjectInfo,
                    mountingHeight: newValue,
                  })
                }
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-6">
              <InputField
                title={t("Summary.Year of construction")}
                id="projectYearOfConstruction"
                value={additionalProjectInfo.yearOfConstruction}
                setValue={(newValue) =>
                  setAdditionalProjectInfo({
                    ...additionalProjectInfo,
                    yearOfConstruction: newValue,
                  })
                }
                type="number"
              />
            </div>
          </div>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-12">
              <div>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    border: "solid 1px",
                  }}
                  onChange={(event) =>
                    setCustomerInfo({
                      ...customerInfo,
                      newsletter: event.target.value,
                    })
                  }
                  id="customerNewsletter"
                />
                <label className="page-title">
                  <p style={{ display: "inline-block" }}>
                    {t("Summary.Newsletter")}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-12">
              <div style={{ display: "flex", alignItems: "top" }}>
                <input
                  type="checkbox"
                  style={{
                    marginTop: "5px",
                    marginRight: "5px",
                    border: "solid 1px",
                  }}
                  onChange={(event) =>
                    setAdditionalProjectInfo({
                      ...additionalProjectInfo,
                      whatsappCheck: event.target.checked ? "Ja" : "Nein",
                    })
                  }
                  id="customerWhatsappCheck"
                />
                <label className="page-title">
                  {t("Summary.Whatsapp service")}
                </label>
              </div>
            </div>
          </div>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-xs-12">
              <div>
                <input
                  type="checkbox"
                  style={{
                    marginRight: "5px",
                    border: "solid 1px",
                  }}
                  required
                />
                <label className="page-title">
                  <a
                    style={{ display: "inline-block" }}
                    href={CurrentUser.dataProtectionLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t("Summary.Agreement")}
                  </a>
                </label>
              </div>
            </div>
          </div>
          <div className="row" style={{ margin: "20px 0" }}>
            <div
              className="col-xs-12"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <label htmlFor="submit-client-info">
                <div
                  style={{
                    backgroundColor: "#0070b8",
                    padding: "0.5rem",
                    display: "flex",
                    paddingLeft: "3rem",
                  }}
                >
                  <div
                    style={{
                      paddingRight: "3rem",
                      fontSize: "1.2rem",
                    }}
                  >
                    <input
                      id="submit-client-info"
                      type="submit"
                      style={{
                        border: 0,
                        padding: 0,
                        backgroundColor: "transparent",
                        outline: "none",
                        margin: "0 auto",
                        color: "white",
                      }}
                      value={t("Accept")}
                    ></input>
                  </div>
                </div>
              </label>
            </div>
          </div>
          <div className="row" style={{ marginTop: "4rem" }}>
            <div className="col-xs-12">
              <h3 className="page-title">{t("Summary.Project summary")}</h3>
            </div>
          </div>
          <div className="row" style={{ width: "100%", marginBottom: "4rem" }}>
            <div className="col-xs-12 horizontal-line" />
          </div>
          {summaryFields}
        </div>
      </form>
    </>
  );
};

export default Summary;
