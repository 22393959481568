import React, { useContext } from 'react';
import { RadioButton } from '../../components/RadioButton';
import ProjectContext from "../../context/ProjectContext";
import '../../css/parameters.css';

export const FirstFloorHeight = ({ t, onProjectDataChange }) => {

  const { projectData, setProjectData } = useContext(ProjectContext);

  function handleFirstFloorChange(value) {
    setProjectData({ ...projectData, firstFloorBalcony: value });
    onProjectDataChange({ firstFloor: value });
  }

  return (
    <>
    <div className="row" style={{ marginTop: "1.5rem", marginBottom: "1.5rem" }}>
        <div
          className="col-xs-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h6 className="page-title col-md-6" style={{ margin: 0 }}>
            {t("Page 3.First floor balcony")}
          </h6>
          <RadioButton
            name="first floor balcony"
            handleChange={handleFirstFloorChange}
          />
        </div>
      </div>
      <div className="horizontal-line" />
    </>
  )
}
